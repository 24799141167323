import React from "react";
import { Link, navigate } from "gatsby-plugin-intl";
import { concatClassNames as cn, accessibleOnClick } from "@system42/core";
import { Headline2Sans } from "../../system42";
import { FeatureIcon } from "../FeatureIcon";
import * as styles from "./styles.module.css";

export function FeatureOverview(props) {
  const { className, title, titleWidth, features } = props;
  return (
    <div className={cn(className, styles.featureOverview)}>
      {/* ToDo: Having the headline here is actually not ideal, as the featureOverview container has different left/right padding on mobile 
        the headline is not in the center anymore… so either remove this froem here or set the padding difference to .features here */}
      <Headline2Sans className={styles.title} style={{ maxWidth: titleWidth }}>
        {title}
      </Headline2Sans>
      <div className={styles.features}>
        {features.map((item, index) => (
          <div
            className={styles.featuresItem}
            key={index}
            {...accessibleOnClick(() => navigate(item.to))}
          >
            <FeatureIcon className={styles.featuresIcon}>
              <img src={item.image} alt={item.alt} />
            </FeatureIcon>
            <Link to={item.to} className={styles.featuresTitle} tabIndex={-1}>
              <span>{item.title}</span>
            </Link>
            <div className={styles.featuresDescription}>{item.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
