import React from "react";
import { concatClassNames as cn } from "@system42/core";
import { ButtonPrimaryLarge, urlCreateAccount } from "../../system42";
import * as styles from "./styles.module.css";

export function HomeTeaser(props) {
  /*  useEffect(() => {
    import("@lottiefiles/lottie-player");
  });*/

  const { className } = props;

  return (
    <div className={cn(className, styles.homeTeaser)}>
      <h2 className={styles.title}>
        <span className={styles.h1Serif}>The easiest way of</span>{" "}
        <span className={styles.highlight}>user testing</span>
      </h2>
      <div className={styles.c2aBox}>
        {/*{<p className={styles.subtitle}>Set up in minutes, results in hours.<br />Your first test is on us.</p>}*/}
        <p className={styles.subtitle}>
          Start testing in minutes. Get results&nbsp;in&nbsp;hours.
        </p>
        <ButtonPrimaryLarge
          className={styles.button}
          customTag={"a"}
          href={urlCreateAccount}
        >
          Start free trial
        </ButtonPrimaryLarge>
      </div>
      {/*    <lottie-player
      autoplay
      mode="normal"
      src={'lottie.json'}
      style={{ width: '100%', maxWidth: '896px', margin: '64px auto 0' }}
    />*/}
    </div>
  );
}
