import React from "react";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@system42/core";
import { ButtonLarge, Headline2Sans } from "../../system42";
import * as styles from "./styles.module.css";
import { ExampleList } from "../ExampleList";

export function Examples({
  className,
  examples,
  title,
  textContent,
  ...containerProps
}) {
  return (
    <div className={cn(styles.examples, className)} {...containerProps}>
      <Headline2Sans className={styles.title}>{title}</Headline2Sans>
      <ExampleList examples={examples} />
      {textContent && <div className={styles.textContent}>{textContent}</div>}
      <div className={styles.buttonContainer}>
        <ButtonLarge customTag={Link} to={"/user-testing-template-library"}>
          View all examples
        </ButtonLarge>
      </div>
    </div>
  );
}
